/*  ----- 5.4 Image    ----- */

.main-img-user {
	display: block;
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
}
.main-img-user img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100%;
}
.avatar-list .avatar {
    margin-bottom: .5rem;
}
.avatar-list .avatar:not(:last-child) {
    margin-right: .5rem;
}
.avatar-list {
    margin: 0 0 -.5rem;
    padding: 0;
    font-size: 0;
}
.avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	background-color: #8760fb;
}
.main-avatar {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	background-color: #8760fb;
}
.main-avatar::after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 3px;
	width: 6px;
	height: 6px;
	background-color: #99a6b7;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
	border-radius: 100%;
}
.main-avatar.online::after {
	background-color: #03c895;
}
.avatar-xs {
	width: 24px;
	height: 24px;
	font-size: 11px;
}
.avatar-xs::after {
	width: 5px;
	height: 5px;
}
.avatar-sm {
	width: 32px;
	height: 32px;
	font-size: 14px;
}
.avatar-sm::after {
	width: 7px;
	height: 7px;
}
.avatar-md {
	width: 48px;
	height: 48px;
	font-size: 24px;
}
.avatar-md::after {
	width: 9px;
	height: 9px;
	right: 2px;
	bottom: 2px;
}
.avatar-lg {
	width: 64px;
	height: 64px;
	font-size: 28px;
}
.avatar-lg::after {
	width: 10px;
	height: 10px;
	bottom: 3px;
	right: 4px;
}
.avatar-xl {
	width: 72px;
	height: 72px;
	font-size: 36px;
}
.avatar-xl::after {
	width: 11px;
	height: 11px;
	bottom: 4px;
	right: 5px;
	box-shadow: 0 0 0 2.5px #fff;
}
.avatar-xxl {
	width: 100px;
	height: 100px;
	font-size: 56px;
}
.avatar-xxl::after {
	width: 13px;
	height: 13px;
	bottom: 6px;
	right: 7px;
	box-shadow: 0 0 0 3px #fff;
}

.scroll-widget{
	width: 100%;
    height: 385px;
    overflow: hidden;
}